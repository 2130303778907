<template>
    <div class="share-options">
        <span>Udostępnij</span>
        <ul>
            <li>
                <a target="_blank" :href="'mailto:?body='+url" title="Udostępnij">
                    <icon icon="share" />
                </a>
            </li>
            <li>
                <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+url" title="Udostępnij na fb">
                    <icon icon="facebook" />
                </a>
            </li>
            <li>
                <a target="_blank" :href="'https://twitter.com/intent/tweet?url='+url" title="Ćwierkaj">
                    <icon icon="twitter" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import Icon from '../utility/Icon';

    export default {
        name: "PageShare",

        components: {
            Icon,
        },

        computed: {
            url() {
                return window.location.href
            }
        },
    }
</script>

<style lang="scss">
    .share-options{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        justify-content: center;
        margin: 80px auto 0;
        border: 1px solid $gray-100;
        border-radius: 8px;

        .icon{
            width: 34px;
            height: 34px;

            path{ fill: $gray-800; }

            @include media-breakpoint-up(lg){
                &:hover path{ fill: $black; }
            }

            &.facebook{
                $color: rgba(66, 103, 178, 1);

                path{ fill: $color; }

                @include media-breakpoint-up(lg){
                    &:hover path{ fill: darken($color, 10); }
                }
            }

            &.twitter{
                $color: rgba(29, 161, 242, 1);

                 path{ fill: $color; }

                @include media-breakpoint-up(lg){
                     &:hover path{ fill: darken($color, 10); }
                }
            }
        }

        span {
            display: block;
            margin: 8px;
            @extend %header-6;
            color: $gray-800;
        }

        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            padding: 0;

            li { margin: 8px; }

            @include media-breakpoint-up(lg){ flex-direction: column; }
        }

        @include media-breakpoint-up(lg){
            flex-direction: column;
            position: sticky;
            top: 12vh;
            margin-top: 0;
        }
    }

    .contrast .share-options{
        border-color: $gray-500;

        span{ color: $black; }

        .icon:not(.facebook):not(.twitter) path{ fill: $black; }
    }
</style>