<template>
    <b-row>
        <b-col cols="12" md="6" lg="4" v-for="(n, i) in items" :key="i" class="my-3">
            <entity-box-listing-item
                :title="n.title.rendered"
                :image="getImage(n)"
                :date="n.date"
                :tags="n._embedded ? n._embedded['wp:term'] ? n._embedded['wp:term'][0] : [] : []"
                :link="'/blog/'+n.slug"
                :status="n.status"
            ></entity-box-listing-item>
        </b-col>
    </b-row>
</template>

<script>
    import EntityBoxListingItem from "./EntityBoxListingItem";
    export default {
        name: "EntityBoxListing",
        components: {EntityBoxListingItem},
        props: ['items'],
      methods:{
          getImage(item) {
            if(!item['_embedded']['wp:featuredmedia']) {
              return null;
            } else {
              const media = item['_embedded']['wp:featuredmedia'][0]
              if(!media['media_details']) {
                return null;
              }
              if(media['media_details']['sizes']['medium_large']) {
                return media['media_details']['sizes']['medium_large']["source_url"];
              } else {
                return media['source_url'] ? media['source_url'] : null
              }
            }
          }

      }
    }
</script>

<style scoped>

</style>