<template>
    <div>
        <header>
            <b-container>
                <b-row v-if="single">
                    <b-col class="bc">
                      <p class="draft" v-if="single.status === 'draft'">SZKIC</p>
                      <b-link v-if="type === 'blog'" to="/blog">Blog</b-link>
                        <b-link v-else to="/news">Aktualności</b-link>
                        <span class="separator">/</span><span v-html="single.title.rendered"></span>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col>
                        <h2 v-if="single" v-html="single.title.rendered"></h2>
                        <ul v-if="tags.length > 0" class="tags">
                            <li class="tag" v-for="(t,i) in tags" :key="i" v-html="t.name"></li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <hr/>
                    </b-col>
                </b-row>
                <b-row v-if="single">
                    <b-col>
                        <span>{{ author.name }}</span><span class="separator">|</span><span>{{ date_from_wp(single.date) }}</span>
                    </b-col>
                </b-row>
            </b-container>
        </header>

        <section v-if="single" class="content">
            <b-container>
                <b-row>
                    <b-col v-html="single.content.rendered" cols="12" lg="10" offset-lg="1"></b-col>
                    <b-col cols="12" lg="1" class="text-center">
                        <page-share></page-share>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <b-container>
            <b-row>
                <b-col>
                    <hr/>
                </b-col>
            </b-row>
        </b-container>

        <section class="related">
            <b-container>
                <b-row v-if="type === 'blog' && tags.length > 0 && related().length > 0">
                    <b-col class="text-center">
                        <h3>Popularne</h3>
                    </b-col>
                </b-row>
                <b-row v-if="type === 'blog' && tags.length > 0 && related().length > 0">
                    <b-col>
                        <entity-box-listing :items="related()"></entity-box-listing>
                    </b-col>
                </b-row>
                <b-row v-if="prevUrl || nextUrl">
                    <b-col v-if="prevUrl">
                        <router-link :to="prevUrl" class="link">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <path class="st0" d="M2.5,11.6c-0.3,0.2-0.3,0.6,0,0.8l7.7,5.9c0.3,0.3,0.8,0,0.8-0.4v-4.4h10c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1 H11V6.1c0-0.4-0.5-0.6-0.8-0.4L2.5,11.6z"/>
                            </svg>
                            <span class="ml-3">Poprzedni artykuł</span>
                        </router-link>
                    </b-col>
                    <b-col v-if="nextUrl" class="text-right">
                        <router-link :to="nextUrl" class="link">
                            <span class="mr-3">Następny artykuł</span>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <path class="st0" d="M21.5,12.4c0.3-0.2,0.3-0.6,0-0.8l-7.7-5.9c-0.3-0.3-0.8,0-0.8,0.4v4.4H3c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1 h10v4.4c0,0.4,0.5,0.6,0.8,0.4L21.5,12.4z"/>
                            </svg>
                        </router-link>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <newsletter></newsletter>
    </div>
</template>

<script>
    import Newsletter from "../components/blocks/Newsletter";
    import { date_formater, tag_filter } from './../components/mixins';
    import PageShare from "../components/utility/PageShare";
    import EntityBoxListing from "../components/utility/EntityBoxListing";

    export default {
        name: "Single",
        components: {EntityBoxListing, Newsletter,PageShare},
        mixins: [date_formater, tag_filter],
        computed: {
            single() {
              let content
              if(this.$route.params.slug === 'preview') {
                content =
                    this.type === 'blog'
                        ? this.$store.getters.blogPostById(this.$route.query.post_id)
                        : this.$store.getters.newsById(this.$route.query.post_id)
              } else {
                content = this.type === 'blog'
                    ? this.$store.getters.post(this.$route.params.slug)
                    : this.$store.getters.news(this.$route.params.slug)
              }
              if(content && content.title) {
                document.title = content.title.rendered + ' - Cyfrowy Dialog'
              }
              return content;
            },
            tags() {
                return this.single
                    ? this.single._embedded['wp:term']
                        ? this.single._embedded['wp:term'][0]
                        : []
                    : []
            },
            author() {
                return this.single ? this.single._embedded.author[0] : ''
            },
            type() {
                return this.$route.meta.type
            },
            nextUrl() {
                return this.getOffsetUrl(1)
            },
            prevUrl() {
                return this.getOffsetUrl(-1)
            }
        },
        methods: {
            getOffsetUrl(offset) {
                let items = this.type === 'blog'
                    ? this.$store.state.content.posts
                    : this.$store.state.content.news
                let n = items.indexOf(this.single)
                return items[n + offset]
                    ? items[n + offset].slug
                    : null
            },
            related() {
                this.filters.tags = this.tags
                let items = this.type === 'blog'
                    ? this.$store.state.content.posts
                    : this.$store.state.content.news
                return items
                    .filter(n => this.filterUsingTag(n))
                    .filter(n => n.id !== this.single.id)
                    .slice(0, 3)
            }
        },
        mounted() {
          if(this.$route.params.slug === 'preview') {
            this.$store.dispatch('getNewsById', this.$route.query.post_id)
            this.$store.dispatch('getBlogPostById', this.$route.query.post_id)
          } else {
            this.type === 'blog'
                ? this.$store.dispatch('getPost', this.$route.params.slug)
                : this.$store.dispatch('getNews', this.$route.params.slug)

            this.type === 'blog'
                ? this.$store.dispatch('getPosts')
                : this.$store.dispatch('getAllNews')
          }
        }
    }
</script>

<style scoped lang="scss">
    .draft {
      padding: 5px 10px;
      background-color: lightgray;
      z-index: 5;
      color: black;
    }
    header {
        background: $primary;
        color: $white;
        padding-top: 5rem;
        padding-bottom: 24px;

        h2{ margin-bottom: 16px; }

        .separator {
            margin: 0 0.75rem;
        }

        .tags{
            display: inline-flex;
            flex-direction: row;
            list-style-type: none;
            flex-wrap: wrap;
            margin: 16px 0 0;
            padding: 0;

            li{
                position: relative;
                padding: 0 5px;
                margin: 0 8px 8px 0;
                cursor: pointer;
                color: $primary;
                background: $blue-100;
                border-radius: 10px;
                transition: 0.15s ease;

                a{
                    display: inline-flex;
                    align-items: center;
                }

                svg{
                    width: 12px;
                    height: 12px;
                    fill: $primary;
                    transition: 0.15s ease;
                    margin-left: 5px;
                }

                &:hover{
                    background: $primary;
                    color: $white;

                    svg{ fill: $white; }
                }
            }

            @include media-breakpoint-up(lg){
                margin: 0;
            }
        }

        hr {
            border-color: #8E9EB6;
            margin-bottom: 16px;
            margin-top: 80px;
        }

        a {
            color: $white;
        }

        .bc {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.3px;

            .separator {
                margin: 0 0.75rem;
            }
        }
    }

    section.content {
        padding-top: 104px;
        padding-bottom: 104px;
    }

    section.related {
        padding-top: 104px;
        padding-bottom: 104px;

        .link{
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            color: $gray-900;
            @extend %navigation-menu;

            svg{
                width: 16px;
                height: 16px;
                fill: $gray-900;
            }
        }
    }

    .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>

<style lang="scss">
    section.content {
        img{
            max-width: 100%;
            height: auto;
        }
    }
</style>