<template>
    <router-link :to="link" class="listing-item">
        <p class="draft" v-if="status === 'draft'">SZKIC</p>
        <picture class="listing-item-picture">
            <img :src="image" :alt="title" />
        </picture>
        <div class="listing-item-content">
            <div class="d-flex flex-row justify-content-between">
                <ul v-if="tags.length > 0" class="tags">
                    <li v-for="(tag, i) in tags" :key="i">{{ tag.name }}</li>
                </ul>
                <span class="date">{{ date_from_wp(date) }}</span>
            </div>
            <h4 v-html="title"></h4>
            <button>Czytaj</button>
        </div>
    </router-link>
</template>

<script>
    import { date_formater } from './../mixins'

    export default {
        name: "EntityBoxListingItem",
        mixins: [date_formater],
        props: {
            image: String,
            tags: Array,
            date: String,
            title: String,
            link: String,
            status: String
        }
    }
</script>

<style scoped lang="scss">
    .draft {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 12px;
      padding: 5px 10px;
      background-color: lightgray;
      z-index: 5;
    }

    .listing-item{
        display: block;
        border-radius: 5px;
        border: 1px solid $gray-100;
        text-decoration: none;

        &-picture{
            position: relative;
            display: flex;
            overflow: hidden;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            // Aspect ratio 5:3
            &::after{
                position: relative;
                content: "";
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 60%;
            }

            img{
                position: absolute;
                left: 0; top: 0; right: 0; bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                transition: 0.3s ease;
            }
        }

        &-content{
            width: 100%;
            padding: 16px 24px 24px 24px;
            background: $white;
            color: $black;

            .tags{
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: inline-flex;
                flex-direction: row;
                list-style-type: none;
                flex-wrap: wrap;

                li{
                    position: relative;
                    padding: 0 10px;
                    margin: 0 8px 8px 0;
                    cursor: pointer;
                    color: $primary;
                    background: $blue-100;
                    border-radius: 10px;
                    transition: 0.15s ease;
                    @extend %header-6;
                }
            }

            .date{
                display: flex;
                @extend %header-6;
                color: $gray-500;
            }
        }

        h4{
            margin-top: 8px;
            margin-bottom: 0;
        }

        button{
            background: none;
            border: none;
            color: $primary;
            padding: 0;
            display: none;

            &:focus{ outline: none; }

            @include media-breakpoint-up(lg){
                display: block;
                opacity: 0;
                transform: translateY(8px);
                transition: 0.3s ease;
            }
        }

        @include media-breakpoint-up(lg){
            &:hover, &:focus{
                outline: none;

                img{ transform: scale(1.04); }

                button{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    .contrast .listing-item{
        &-content{
            .tags{
                li{
                    background: $white;
                    border: 1px solid $primary;
                    font-weight: 600;
                }
            }

            .date{
                color: $gray-700;
            }
        }
    }
</style>
