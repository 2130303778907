import { render, staticRenderFns } from "./EntityBoxListingItem.vue?vue&type=template&id=645ec3fc&scoped=true&"
import script from "./EntityBoxListingItem.vue?vue&type=script&lang=js&"
export * from "./EntityBoxListingItem.vue?vue&type=script&lang=js&"
import style0 from "./EntityBoxListingItem.vue?vue&type=style&index=0&id=645ec3fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645ec3fc",
  null
  
)

export default component.exports